.match-winner {
  background-color: #86C232;
}
.match-loser {
  background-color: #F76C6C;
}
.match-prediction {
  background-color: #A3A300;
}
body {
  font-size: calc(10px + 2vmin) !important;
}
